<div class="search-input-icon-container">
  <gray-icon *ngIf="!searching" class="search-icon" icon="search"></gray-icon>
  <div *ngIf="searching" class="search-loader spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <input
    type="text"
    [placeholder]="'labels.search'|translate"
    class="search-input ms-2 form-control"
    [(ngModel)]="model" [ngbTypeahead]="search"
    [popupClass]="'search-result-list-container'"
    (selectItem)="onSelect($event)"
    [resultTemplate]="customResultTemplate"/>
  <ng-template #customResultTemplate let-r="result" let-t="term" class="w-100">
    <span *ngIf="r['type'] == 'blog'" class="d-flex align-items-center search-result-item">
      <gray-icon icon="blog"></gray-icon>
      <p class="ms-1 fs-normal color-gray-400 fw-500 max-line-2">{{r['object']['title']}}</p>
    </span>

    <span *ngIf="r['type'] == 'video'" class="d-flex align-items-center search-result-item">
      <gray-icon icon="videos-thin"></gray-icon>
      <p class="ms-1 fs-normal color-gray-400 fw-500 max-line-2">{{r['object']['title']}}</p>
    </span>

    <span *ngIf="r['type'] == 'live'" class="d-flex align-items-center search-result-item">
      <gray-icon icon="stream"></gray-icon>
      <p class="ms-1 fs-normal color-gray-400 fw-500 max-line-2">{{r['object']['title']}}</p>
    </span>

  </ng-template>
</div>
